@use '../../style/variables' as *;

.couches-table-card-wrapper {
    margin: 5px 1px 1px !important;

    .img-couches {
        width: 24px;
        height: 24px;
        max-width: 24px;
        max-height: 24px;
        box-shadow: 0 0 0 1px $p300; // It must be box shadow, because if we use outline it wont work on IOS devices
        border-radius: 50%;
    }

    .table-card-divider {
        margin-top: 18px;
        margin-bottom: 18px;
    }
}
