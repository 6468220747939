@use '../../style/variables' as *;

.no-data-for-view-wrapper {
    background: $n0;
    border: 1px solid $n100;
    border-radius: 8px;
    p {
        color: $n400;
    }

    .button-margin {
        margin-top: 24px;
        margin-bottom: 24px;
    }
}
