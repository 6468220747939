@use '../../../../../style/variables' as *;

.fourth-step-wrapper {
    .referee-type-radio-button {
        .radio-wrapper {
            label {
                white-space: nowrap;
            }
        }
    }
}
