@use "../../style/variables" as *;

.table-clubs-ranking-wrapper {
    background-color: $n0;
    border-radius: 0px 0px 4px 4px;
    padding-top: 7px;

    hr {
        margin: 0;
    }
    // p {
    //     color: $n300;
    //     text-decoration: none;
    // }

    th {
        padding: 18px 16px;

        h5 {
            // color: #3b485c;
            color: $p700;
        }
    }
    td {
        padding: 16px;
    }

    .link-align-end {
        text-align: end;
    }

    .table-points-input-field {
        input {
            padding: 1px 2px;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.025em;
            color: $n800;
            margin: 0;
            font-style: normal;
            font-family: $font-heading;
            font-weight: 400;
            text-align: start;

            &:hover{
                background-color: $n50;
            }

            &:not(:focus){
                border: 1px solid transparent;

                &:hover{
                    cursor: pointer;
                }
            }
        }
    }

}
