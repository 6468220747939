@use '../../../style/variables' as *;

.competitors-wrapper {
    .competitors-buttons {
        column-gap: 8px;
    }

    .tabular-navigation {
        background: $n0;
        border-bottom: 1px solid $n100;
        border-radius: 4px 4px 0px 0px;
    }

    @media only screen and (max-width: $md) {
        .nav::-webkit-scrollbar {
            display: none;
        }

        .nav {
            flex-wrap: nowrap;
            overflow-x: scroll;
        }

        .nav-item {
            .nav-link {
                width: max-content;
            }
        }
    }

    .notification-wrapper {
        position: relative;

        .notification {
            font-family: $font-heading;
            font-weight: 700;
            display: inline-block;
            background: $r500;
            color: $n0;
            border-radius: 50%;
            padding: 3px 8px;
            position: absolute;
            top: 0;
            transform: translate(-5%, -45%);
            font-size: 10px;
            line-height: 16px;
            text-align: center;
            letter-spacing: 0.03em;
            font-feature-settings: 'liga' off;
        }
    }
}

.yearDropdown{
    .dropdown-wrapper{
        .dropdown-menu{
            height: 288px; //height of each year item x 6 - 6years visible in dropdown with scroll
            overflow-y: scroll;
        }
    }
}

.invitation-spinner{
    border-color: $p500;
    border-right-color: transparent;
}
