.no-clubs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    grid-gap: 10px;

    svg {
        transform: scale(1.2);
        margin-bottom: 55px;
    }

    h2 {
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: #3b485c;
    }

    p {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.025em;
        max-width: 550px;
        text-align: center;
        margin-bottom: 32px;
    }
}
