@use '../../style/variables' as *;

.competition-report {
    h4 {
        margin-left: 16px;
        padding: 16px 0;
    }

    td {
        padding: 14px 16px;
    }

    th {
        padding: 14px 16px;
    }

    .accordion {
        border: none;

        .accordion-item {
            border: none;
        }
    }

    .accordion-body {
        border-bottom: 0;
    }
    .accordion-button {
        border-bottom: 1px solid $n100;
        &:focus {
            box-shadow: none;
        }

        &:not(.collapsed) {
            color: $n500;
            background-color: $n0;
        }
    }

    .result-row {
        padding: 16px 20px;
        border-bottom: 1px solid $n100;
    }

    td.align-middle {
        p {
            color: $n500;
        }

        .d-flex {
            p {
                color: $n300;
            }
        }
    }
}
