@use '../../../../../../style/variables' as *;

.upload-images-wrapper {
    display: flex;
    flex-direction: column;
    grid-gap: 24px;

    h4 {
        font-size: 16px;
    }

    label {
        cursor: pointer;
        width: 100%;
        border: 1px solid #6686a3;
        border-radius: 4px;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        padding: 14px 0;
        text-align: center;
    }
}

.club-uploaded-img {
    .remove-image-wrapper {
        position: absolute;
        top: -8px;
        right: -8px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: $e600;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 10;
    }
    .single-club-image-wrapper {
        position: relative;
        padding: 0;
        width: 100%;
        display: block;

        &:hover {
            img {
                overflow: hidden;
                width: 100%;
                cursor: pointer;
                filter: blur(0.5px);
                -webkit-transition: all 0.5s ease;
                -moz-transition: all 0.5s ease;
                -o-transition: all 0.5s ease;
                transition: all 0.5s ease;
            }

            .download-icon {
                opacity: 1;
                z-index: 10;
                cursor: pointer;
            }

            .download-icon-wrapper {
                z-index: 9;
                opacity: 1;
                cursor: pointer;
            }
        }

        .download-icon-wrapper {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            width: max(calc(100% - 20px), 30px);
            height: max(calc(100% - 20px), 30px);
            background-color: $p500;
            border-radius: 50%;
            opacity: 0;
        }

        .download-icon {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            width: max(calc(100% - 30px), 24px);
            height: max(calc(100% - 30px), 24px);
            text-align: center;
            color: $n0;
            opacity: 0;
            -webkit-transition: all 0.5s ease;
            -moz-transition: all 0.5s ease;
            -o-transition: all 0.5s ease;
            transition: all 0.5s ease;
        }

        img {
            width: 100%;
        }
    }
}

.club-uploaded-img-modal {
    img {
        width: 100%;
    }
}

.club-profile-section {
    gap: 24px;

    @media only screen and (max-width: $md) {
        gap: 8px;
    }

    .admin-flow-club-profile-card-wrapper {
        border: 1px solid $n100;
        border-radius: 8px;

        .admin-flow-club-profile-card-body {
            padding: 32px 24px 16px 24px;

            &.details-card {
                .details-header-wrapper {
                    h5.details-h5 {
                        color: $p600;
                        cursor: pointer;
                    }
                }

                .details-single-info {
                    padding: 6px 16px;

                    .title {
                        color: $n300;
                    }

                    .data-info {
                        color: $n500;
                    }
                }
            }

            &.history-card {
                .history-text {
                    color: $n500;
                }
            }

            &.courts-card {
                padding: 32px 24px 24px 24px;

                .courts-card-section {
                    &:not(:last-child) {
                        margin-bottom: 32px;
                    }

                    .courts-card-section-title {
                        margin-left: 8px;
                        margin-bottom: 16px;
                    }
                }

                .single-court {
                    padding: 6px 16px;

                    .courts-number {
                        color: $n500;
                    }

                    .small-text-gray {
                        color: $n400;
                    }
                }
            }
        }

        .admin-flow-club-profile-card-footer {
            padding: 16px 24px;
            background-color: $n0;
            border-top: 1px solid $level-2;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    }
}

.competition {
    gap: 24px;
    max-height: 600px;

    .total-matches {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;

        p {
            margin-bottom: 14px;
        }
    }

    .matches {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 32px;
        margin-bottom: 60px;
        margin-top: 24px;

        .match {
            gap: 8px;
            align-items: center;
        }

        .green {
            width: 16px;
            height: 16px;
            border-radius: 30px;
            background-color: $g500;
        }

        .red {
            width: 16px;
            height: 16px;
            border-radius: 30px;
            background-color: $e500;
        }
    }
}
