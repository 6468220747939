@use '../../style/variables' as *;

.table-competitors-pagination-wrapper {
    overflow-x: scroll;
    p {
        color: $n700;
    }

    .link {
        color: $p500;
    }

    th {
        padding: 18px 16px;

        h5 {
            color: $p700;
        }
    }
    td {
        padding: 16px;
    }

    .table-data-min-width {
        min-width: 180px;
    }

    .table-dates-min-width {
        min-width: 121px;
    }
}
