@use '../../style/variables' as *;


.pill-style {
    font-family: $font-heading;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    @media only screen and (max-width: $md) {
        & {
            font-family: $font-heading;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
        }
    }
}
.pill-active {
    background: $g50;
    border: 1px solid $g500;
    border-radius: 24px;
    width: max-content;
    padding: 2px 8px;
    grid-gap: 4px;
    p {color: $n500} 
}

.pill-punished {
    background: $w50;
    border: 1px solid $w500;
    border-radius: 24px;
    width: max-content;
    padding: 2px 8px;
    grid-gap: 4px;
    p {color: $n500} 
}

.pill-inactive {
    background: $e50;
    border: 1px solid $e500;
    border-radius: 24px;
    width: max-content;
    padding: 2px 8px;
    grid-gap: 4px;
    p {color: $n500} 
}

.pill-default {
    background: #f3f5f6;
    border: 1px solid #a4acb6;
    border-radius: 24px;
    width: fit-content;
    padding: 2px 8px;
    grid-gap: 4px;
    p {color: $n500} 
}

.pill-badge-success {
    background-color: $sg50;
    border: 1px solid $sg50;
    border-radius: 16px;
    width: fit-content;
    padding: 2px 5px;
    grid-gap: 4px;
    color: $sg700;
    font-family: $font-heading;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;

    svg {
        color: $sg500;
    }
}
