@use '../../style/variables' as *;

.club-list-table-card-wrapper {
    margin: 5px 1px 1px !important;

    .table-card-divider {
        margin-top: 16px;
        margin-bottom: 16px;
    }
}
