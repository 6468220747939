@use '../../../../style/variables' as *;

.competitor-profile-wrapper {
    // background: $n50;

    @media only screen and (max-width: $md) {
        .nav::-webkit-scrollbar {
            display: none;
        }

        .nav {
            flex-wrap: nowrap;
            overflow-x: scroll;
        }

        .nav-item {
            // max-height: 54px;

            .nav-link {
                width: max-content;
            }
        }
    }

    .card-profile {
        background: $n0;
        border: 1px solid $n100;
        border-radius: 8px;
    }

    .competitors-profile-buttons {
        column-gap: 8px;
    }

    .tabular-navigation {
        border-bottom: none;
    }
}
