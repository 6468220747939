@use '../../style/variables' as *;

.button-wrapper {
    padding: 14px 24px;
    min-width: max-content;

    .leading-icon {
        svg {
            width: 22px !important;
            height: 22px;
        }
    }

    .end-icon {
        margin-left: 12px;
    }

    h5 {
        line-height: 22px;
    }
}

.btn-variant-primary {
    border: solid 1px $p500;
    background-color: $p500;
    border-radius: 4px;

    &:focus:not(:focus-visible) {
        box-shadow: none;
        background-color: $p700;
        h5 {
            color: $n0;
        }
    }

    h5 {
        color: $n0;
    }

    svg {
        fill: $n0;
    }

    &:hover {
        background-color: $p800;
        h5 {
            color: $n0;
        }
        svg {
            fill: $n0;
        }
    }

    &:active {
        background-color: $p900;
        h5 {
            color: $n0;
        }
        svg {
            fill: $n0;
        }
    }

    &:focus-visible {
        background-color: $p700;
        box-shadow: 0px 0px 0px 2px #ffffff, 0px 0px 0px 4px #232e40;
        outline: none;
    }

    &:disabled {
        background-color: $n50;
        h5 {
            color: $n200;
        }
        border: 1px solid $n50;
        svg {
            fill: $n200;
        }
    }
}

.btn-variant-secondary {
    border: 1px solid $p300;
    border-radius: 4px;
    background-color: $n0;

    h5 {
        color: $n500;
    }

    svg {
        fill: $n500;
    }

    &:focus:not(:focus-visible) {
        background-color: $p100;
        h5 {
            color: $n500;
        }
        svg {
            fill: $n500;
        }
    }

    &:focus-visible {
        background-color: $p100;
        box-shadow: 0px 0px 0px 2px #ffffff, 0px 0px 0px 4px #232e40;
        border: 1px solid $p300;
        outline: none;
    }

    &:hover {
        border: 1px solid $p300;
        background-color: $p50;
    }

    &:active {
        border: 1px solid $p300;
        background-color: $p100;
    }

    &:disabled {
        background: $n50;
        border: 1px solid $n100;
        h5 {
            color: $n200;
        }
        svg {
            fill: $n200;
        }
    }
}

.btn-variant-text {
    background: none;
    border: 1px solid transparent;
    border-radius: 4px;

    h5 {
        color: $n500;
    }

    svg {
        fill: $n500;
    }

    &:focus:not(:focus-visible) {
        background-color: $p100;
        border: 1px solid $p100;
    }

    &:focus-visible {
        background-color: $p100;
        box-shadow: 0px 0px 0px 2px #ffffff, 0px 0px 0px 4px #232e40;
        border: 1px solid $p100;
        outline: none;
    }

    &:hover {
        background-color: $p50;
        border: 1px solid $p50;
    }

    &:active {
        background-color: $p100;
        border: 1px solid $p100;
    }

    &:disabled {
        background-color: $n50;
        border: 1px solid $p50;

        h5 {
            color: $n200;
        }

        svg {
            fill: $n200;
        }
    }
}
