@use '../../../style/variables' as *;

.player-edit-profile {
    background: $n0;
    height: calc(100% + 180px);
    padding: 32px 24px;
    @media only screen and (max-width: $md){
        padding: 16px 8px;
    }
    overflow-y: hidden;

    .tab-content{
        width: 100%;
    }

    //temp commented out bcs of layout change

    // .change-competitor-tab{
    //     @media only screen and (min-width: $md) {
    //         padding-right: 290px;
    //         }
    // }

    .upload-img{
        border: none;
    }
}
