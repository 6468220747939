@use '../../../style/variables' as *;

.punishments-tab-wrapper {
    column-gap: 25px;
    row-gap: 8px;

    .small-text {
            color: $n300;
    }
}
