@use '../../style/variables' as *;

.dropdown-wrapper {
    .dropdown-toggle {
        /* Dropdown button style*/
        background-color: $n0;
        border: 1px solid $n200;
        padding: 12px 16px;

        /* Dropdown button paragraph style*/
        p {
            // color: $n200;
            text-overflow: ellipsis;
            overflow: hidden;

            &.placeholder-style {
                color: $n200;
            }
        }
        /* Dropdown arrow style, transition and scale */
        &::after {
            color: $n500;
            transition: 0.5s;
            border-top: 0.5em solid;
            border-right: 0.5em solid transparent;
            border-left: 0.5em solid transparent;
        }

        /* Dropdown arrow rotation on click */
        &[aria-expanded='true']::after {
            transform: rotate(180deg);
        }

        &:active {
            background-color: $n0;
            border: 1px solid $n200;
        }
    }
    .dropdown-menu {
        /* Dropdown menu style */
        padding: 0;
        border: 1px solid $n200;
        background-color: $n0;

        .dropdown-item {
            padding: 14px 16px;

            /* Dropdown item paragraph color */
            p {
                color: $n400;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            /* Dropdown item hover, active and focus state */
            &:active,
            &:hover,
            &:focus {
                background-color: $n50;
                border-radius: 0.375rem; // same as Dropdown menu, so the border will not be sliced on the corners
            }
        }
    }
}
