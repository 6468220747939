@use '../../style/variables' as *;

.medical-history-view{
	background: $n0 !important;
    height: calc(100% + 100px);

	.medical-history-wrapper{
		height: 100%;
	}

	.medical-history-container {
        max-width: 616px;
        @media only screen and (max-width: $md) {
            min-width: 100%;
        }
    }

	.medical-history-list {
        width: 100%;
        @media only screen and (max-width: $md) {
            background-color: $n50;
        }
    }

	.add-medical-col{
		border-bottom: 1px solid $n50;
	}

	.close-icon {
        padding-top: 30px;
        cursor: pointer;
    }
    .medical-history-title {
        margin-bottom: 32px;
    }

	.single-medical {
        padding: 14px 0;
        border-bottom: 1px solid $n50;
        width: 100%;

        &.club-membership,
        &.competitor-membership {
            padding: 18px 16px;
            gap: 32px;
        }

        .medical-info-text {
            color: $n500;
        }

        .medical-header {
            color: $text-0;
        }
    }

    .medical-history-buttons-wrapper {
        gap: 8px;
        margin-top: 32px;
        width: 100%;
    }
}