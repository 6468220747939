@use '../../style/variables' as *;

.tournament-round-card {
    border: none;
    border-top: 5px solid $p500;
    border-radius: 4px 4px 0 0;
    padding: 16px 24px;
    height: 100%;
    min-height: 100vh;
    max-height: fit-content;

    .tournament-round-name {
        background-color: $n50;
        border-radius: 20px;
        padding: 4px 0;
    }
}

.tournament-round-card-p500 {
    border-top: 5px solid $p500;
}

.tournament-round-card-g500 {
    border-top: 5px solid $g500;
}

.tournament-round-card-s500 {
    border-top: 5px solid $s500;
}

.tournament-round-card-w500 {
    border-top: 5px solid $w500;
}
