@use '../../style/variables' as *;

.table-referee-competitors-pagination-wrapper {
    p {
        color: $text-0;
    }

    .link {
        color: $p500;
    }

    th {
        padding: 18px 16px;

        h5 {
            color: $text-0;
        }
    }
    td {
        padding: 16px;
    }
}
