@use '../../../../style/variables' as *;

.draft-player-edit-wrapper {
    // height: calc(100% + 100px);
	height: 100%;
	max-width: 1000px;
	
	&-inside{
		gap: 24px;
	}
}