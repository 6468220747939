.tournament-match-card-for-pdf{
    position: relative;
    border-top: 2px solid #000;
    border-right: 2px solid #000;
    border-bottom: 2px solid #000;
    width: 100%;
    height: 50px;

    .side-1{
        position: absolute;
    }
    .side-2 {
        position: absolute;
    }

}
.winner-line{
    border-bottom: 2px solid #000;
    width: fit-content;
}