@use '../../style/variables' as *;

.statistics-card {
    background-color: $n0;
    border: 1px solid $n100;
    padding: 24px;

    &.chartSmall,
    &.chartBig {
        padding-top: 32px;
    }

    &.chartMedium {
        padding: unset;

        .card-body {
            padding: 24px;
        }
    }

    .statistics-card-image {
        width: 100px;
        height: 100px;
    }

    .statistics-card-content {
        .statistics-card-chart-sm {
            .chart-gender {
                width: 120px;
                height: 120px;
            }

            .chart-info {
                .small-text {
                    color: #384250;
                }

                .chart-info-badge {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 6px;
                    height: 6px;
                    border-radius: 30px;

                    &.left {
                        background-color: $s400;
                    }

                    &.right {
                        background-color: $p600;
                    }
                }
            }
        }

        .statistics-card-chart-md {
            .chart-medium {
                width: 240px;
                height: 240px;
            }
        }
    }

    .statistics-card-medium-footer {
        padding: 16px;
        background-color: $n0;
        border-top: 1px solid $n100;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        .segment-wrapper {
            column-gap: 8px;
            min-width: fit-content;
            margin-right: 12px;
            .segment {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 12px;
                height: 12px;
                border-radius: 30px;
            }

            .small-text {
                text-transform: uppercase;
                color: $n500;
            }
        }
    }

    &:hover{
        transition: 200ms background;
        background-color: $n50;
    }
}

.chart-percent {
    fill: $n0;
}
