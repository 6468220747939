@use '../../../../style/variables' as *;

// .create-new-competitions-wrapper {
//     background: $n0 !important;
//     overflow-x: none;

//     .close-icon {
//         cursor: pointer;
//     }

//     h2 {
//         color: $text-0;
//     }
// }

.create-new-competition-wrapper {
    background: $n0 !important;
    height: calc(100% + 100px);

    .create-competition-wrapper {
        height: 100%;
    }

    .form-wrapper {
        padding-bottom: 50px;
    }

    .close-icon {
        cursor: pointer;
    }

    h2 {
        color: $text-0;
    }

    .competition-form-wrapper {
        .form-footer-buttons {
            gap: 8px;

            .footer-button {
                @media only screen and (max-width: $md) {
                    width: 100%;
                }
            }
        }
        .form-columns {
            min-width: 296px;
            max-width: 296px;

            @media only screen and (max-width: $md) {
                min-width: 100%;
                max-width: 100%;
            }

            .create-new-competition-form-wrapper {
                .type-radio-buttons-wrapper {
                    .radio-wrapper {
                        label.form-check-label {
                            font-family: $font-heading;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 20px;
                            letter-spacing: 0.025em;
                            color: $n500;
                        }
                    }
                    &.gender,
                    &.tieWinner {
                        .radio-wrapper {
                            margin-right: 0 !important;
                        }
                        .form-check {
                            margin-bottom: 16px;
                            &.form-check-inline {
                                margin-right: 0;
                            }
                        }
                    }
                }

                .step-four {
                    h3.contact-section-title {
                        color: black;
                    }
                }
            }
        }
    }
}
