@use '../../../style/variables' as *;

.statistics-wrapper {
    .empty-view {
        height: 70vh;
        width: 100%;
        margin: 0 auto;
    }

    .statistics-charts {
        column-gap: 24px;
        row-gap: 16px;

        .row-gap {
            row-gap: 16px;
        }
    }
}
