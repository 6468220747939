@use '../../style/variables' as *;

.sidebar-wrapper {
    width: 100%;
    background: $n0;
    border-right: 1px solid $n100;
    height: 100vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
    img {
        width: 265px;
        max-width: 265px;
    }

    .user-card {
        background: $p50;
        border-radius: 8px;
        padding: 8px 16px;

        .email {
            color: $n400;
        }
    }

    .sidebar-logo{
        cursor: pointer;
    }
}
