@use '../../../style/variables' as *;

.club-card {
    border: none;
    border-radius: 0;

    img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        max-width: 32px;
        max-height: 32px;
        box-shadow: 0 0 0 1px $p300; // It must be box shadow, because if we use outline it wont work on IOS devices
    }
}

.white-club-card {
    background-color: $n0;
    .car-row {
        padding: 16px 0;
        border-bottom: 1px solid $n100;

        &:last-child{
            border-bottom: none;
        }
    }
}

.grey-club-card {
    background-color: $n100;
    .car-row {
        padding: 16px 0;
        border-bottom: 1px solid $n0;

        &:last-child{
            border-bottom: none;
        }
    }
}
