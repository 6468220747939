@use './style/variables' as *;
@import 'bootstrap/dist/css/bootstrap.min.css';

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
h5,
p,
figure,
blockquote,
dl,
dd {
    margin: 0;
}

tbody {
    display: flex;
    flex-direction: column;

    tr {
        display: flex;
        justify-content: space-between;
    }

    td {
        display: flex;
        align-items: center;
        width: 100%;
    }
}

thead {
    display: flex;
    flex-direction: column;
    tr {
        display: flex;
        justify-content: space-between;
    }
    th {
        display: flex;
        align-items: center;
        width: 100%;
    }
}

body {
    // Block scroll on body to disable double scroll on mobile devices
    overflow-x: hidden;
    overflow-y: hidden;
}

.sidebar-content {
    height: 100vh;
    padding: 0;
    flex: 0 0 290px;
}

.public-main-content{
    height: 100vh;
        overflow-y: hidden;
        overflow-x: hidden;
        background-color: $n0;
        flex: 1;
    
        .main-content-row {
            @media only screen and (max-width: $md) {
                background-color: $n0;
            }
        }
    
        .main-scroll {
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            padding-bottom: 180px;
    
            /* width */
            &::-webkit-scrollbar {
                width: 18px;
    
                @media only screen and (max-width: $md) {
                    width: 0;
                }
            }
    
            /* Track */
            &::-webkit-scrollbar-track {
                background: transparent;
            }
    
            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: rgba(26, 26, 26, 0.1);
                border-radius: 100px;
                border: 7px solid $n50;
            }
    
            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: rgba(26, 26, 26, 0.2);
            }
        }
}

.main-content {
    height: 100vh;
    overflow-y: hidden;
    background-color: $n50;
    flex: 1;
    .main-content-row {
        @media only screen and (max-width: $md) {
            background-color: $n0;
        }
    }
    .main-scroll {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        padding-bottom: 180px;

        /* width */
        &::-webkit-scrollbar {
            width: 18px;
            @media only screen and (max-width: $md) {
                width: 0;
            }
        }
        /* Track */
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: rgba(26, 26, 26, 0.1);
            border-radius: 100px;
            border: 7px solid $n50;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: rgba(26, 26, 26, 0.2);
        }
    }
}

.progress {
    border-radius: 0;
}

.progress-bar {
    background-color: $p400;
    height: 100% !important;
}

a {
    color: $n500;
    text-decoration: none;
}

.link-text {
    text-decoration: none;
}

.nav-tabs .nav-link {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: $n300;
    border: none;
    background-color: $n0;
    padding: 16px !important;
    svg {
        fill: $n300;
    }
}

.nav-tabs .nav-link.active {
    color: $n500;
    border-bottom: 2px solid $p500;
    svg {
        fill: $n500;
    }
}

.nav-tabs {
    @media only screen and (min-width: $md) {
        padding-left: 16px;
    }

    // Prevent scrolling of the tabs vertically and draggable effect on mobile devices
    @media only screen and (max-width: $md) {
        overflow-y: hidden;
    }
}

// Modals

.modal {
    --bs-modal-width: 390px;
}
.modal-header {
    border-bottom: none;
}

.modal-footer {
    border-top: none;
}

.modal-backdrop.show {
    opacity: 0.1;
}

// TSS logo

.tss-logo {
    width: 265px;
    max-width: 265px;

    &-header{
        width: 80%;
    }
}
