@use '../../../style/variables' as *;

.membership-view {
    background: $n0 !important;
    height: calc(100% + 100px);

    .membership-wrapper {
        height: 100%;
    }

    .memberships-container {
        max-width: 616px;
        @media only screen and (max-width: $md) {
            min-width: 100%;
        }
    }

    .membership-list {
        width: 100%;
        @media only screen and (max-width: $md) {
            background-color: $n50;
        }
    }

    .add-membership-col {
        border-bottom: 1px solid $n50;
    }

    .close-icon {
        padding-top: 30px;
        cursor: pointer;
    }
    .membership-title {
        margin-bottom: 32px;
    }

    .single-membership {
        padding: 14px 48px;
        border-bottom: 1px solid $n50;
        width: 100%;
        // padding: 18px 16px;
        gap: 32px;

        .membership-year {
            color: $n500;
        }
        .membership-paid {
            color: $n300;
        }

        .membership-status {
            &-active {
                color: $g500;
            }
            &-inactive {
                color: $e500;
            }
            &-stand {
                color: $w500;
            }
        }

        .membership-header {
            color: $text-0;
        }
    }

    .membership-buttons-wrapper {
        gap: 8px;
        margin-top: 32px;
        width: 100%;
    }
}

.membership-card-wrapper {
    margin: 5px 1px 1px !important;

    .table-card-divider {
        margin-top: 16px;
        margin-bottom: 16px;
    }

    &.white-membership-card {
        background-color: $n0;
        // .ref-comp-card-row {
        //     padding: 16px 0;
        //     border-bottom: 1px solid $n100;
        // }
    }

    &.grey-membership-card {
        background-color: $n100;
        // .ref-comp-card-row {
        //     padding: 16px 0;
        //     border-bottom: 1px solid $n0;
        // }
    }

    .membership-year {
        color: $n700;
    }
    .membership-paid {
        color: $n700;
    }

    .membership-status {
        &-active {
            color: $g500;
        }
        &-inactive {
            color: $e500;
        }
        &-stand {
            color: $w500;
        }
    }
}
