@use '../../style/variables' as *;

.upload-img {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 160px;
    height: 160px;
    background-color: $n50;
    border-radius: 50%;
    grid-gap: 13px;
    border: 8px solid $n0;
    box-shadow: 0 0 0 1px $p300; // It must be box shadow, because if we use outline it wont work on IOS devices

    cursor: pointer;
    overflow: hidden;
    margin: 0 auto 16px auto;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &.disabled {
        cursor: default;
    }
}
