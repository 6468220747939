@use '../../../../style/variables' as *;

.create-new-referee-wrapper {
    background: $n0 !important;
    height: calc(100% + 100px);

    .create-referee-wrapper {
        height: 100%;
    }

    .referee-form-wrapper {
        padding-bottom: 50px;

        .form-footer-buttons {
            gap: 8px;
    
            .footer-button {
                @media only screen and (max-width: $md) {
                    width: 100%;
                }
            }
        }
        .form-columns {
            min-width: 296px;
            max-width: 296px;
    
            @media only screen and (max-width: $md) {
                min-width: 100%;
                max-width: 100%;
            }
        }
    }

    .close-icon {
        cursor: pointer;
    }

    h2 {
        color: $text-0;
    }
}
