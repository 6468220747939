@use '../../style/variables' as *;

.welcome-component-wrapper {
    background: linear-gradient(104.03deg, #466c8f -11.04%, #1b4168 101%);
    border-radius: 4px;
    background-position: center;
    padding: 24px;

    @media only screen and (max-width: $md) {
        & {
            padding: 5px;
        }
    }

    p {
        color: $n0;
    }
    h2 {
        color: $n0;
    }
    h3 {
        color: $n0;
    }

    .welcome-intro {
        color: $n0;
        margin-bottom: 40px;
    }
}
