@use '../../style/variables' as *;

.forgot-password-wrapper {
    padding: 0 9vw;

    .link-text {
        text-decoration: none;
    }

    .header {
        margin-top: 56px;
    }
    .chainapp-text {
        font-size: 12px;
    }
}
