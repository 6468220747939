@use '../../../../style/variables' as *;

.referee-profile-wrapper {
    background: $n50;
    .referee-background {
        background: url('../../../../assets/images/competition-img-3.jpg');
        background-position: center;
        background-size: cover;
    }
    .card-profile {
        background: $n0;
        border: 1px solid $n100;
        border-radius: 8px;
    }

    .account-card {
        word-break: break-word;
    }

    .card-title-color {
        color: $n500;
    }

    .referee-region-card {
        background: $n0;
        border: 1px solid $n100;
        border-radius: 8px;
        padding: 16px;
    }

    .info-cards-wrapper {
        gap: 16px;

        @media only screen and (max-width: $md) {
            gap: 8px;
        }
    }

    .competitor-flow-profile-card-wrapper{
        border-radius: 4px;
    }
}
