@use '../../style/variables' as *;

$--background-color: #212121;
$--spinner-border-width: 12px;
$--spinner-animation-speed: 1.5s;
$--spinner-animation-name: spinner-border;

.spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($color: $--background-color, $alpha: 0.8);
    z-index: 999999999999;
    .spinner-wrapper {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        .spinner-border {
            position: absolute;
            width: 258px;
            height: 258px;
            border: $--spinner-border-width solid $g600;
            border-right-color: transparent;
            --webkit-animation: $--spinner-animation-speed linear infinite
                $--spinner-animation-name;
            animation: $--spinner-animation-speed linear infinite
                $--spinner-animation-name;
        }
        .spinner-text {
            position: absolute;
            color: $n0;
            font-family: $font-main;
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
        }
    }
}
