@use '../../style/variables' as *;

.info-card {
    background: $n0;
    border: 1px solid $n100;
    border-radius: 4px;
    padding: 32px;

    .subtitle {
        margin-top: -20px;

        &.subtitle-padding {
            padding-bottom: 10px;
        }
    }

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        background-color: $p50;
        width: fit-content;
        height: fit-content;
        border-radius: 8px;
        margin-right: 24px;
        svg {
            width: 27px;
            fill: $p600;
        }
    }

    .card-photo {
        img {
            width: 55px;
            height: 55px;
            max-width: 55px;
            max-height: 55px;
            border-radius: 8px;
            border: 1px solid $p300;
            margin-right: 24px;
        }
    }
}
