@use '../../../../style/variables' as *;

.competition-profile {
    .nav::-webkit-scrollbar {
        display: none;
    }

    .nav {
        flex-wrap: nowrap;
        overflow-x: scroll;
    }

    .nav-item {
        // max-height: 54px;

        .nav-link {
            width: max-content;
        }
    }

    .rounds-wrapper {
        flex-wrap: nowrap;
        overflow-x: scroll;

        &::-webkit-scrollbar {
            width: 18px;
            @media only screen and (max-width: $md) {
                width: 0;
            }
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: $n100;
            border-radius: 100px;
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $p500;
            border-radius: 100px;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
        background: $p600;
        }
    }

    .competition-info-card {
        background: $n0;
        border: 1px solid $n100;
        border-radius: 4px;
        padding: 32px;

        .small-text {
            color: $n300;
        }
    }

    .competiton-region-card {
        border: 1px solid $n100;
        padding: 16px;
    }

    .tabular-navigation {
        border-bottom: none;
    }

    .tournament-description-images img {
        width: 100%;
    }

    .report-tab-wrapper {
        background-color: $n0;
        // height: 100vh;

        .report-pill-content {
            p {
                color: $n500;
            }
            min-width: 135px;
        }
    }

    .competition-finished-switch {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;

        .form-check-input {
            margin: auto;
            width: 56px;
            height: 24px;
            cursor: pointer;
            box-shadow: none;
        }

        .competition-finished-switch-label {
            color: $n500;
        }
    }
}
