@use '../../../../../style/variables' as *;

.profile-tab-wrapper {
    // width: 100%;
    column-gap: 25px;
    row-gap: 8px;
    .card-info-competitor {
        height: fit-content;
        background: $n0;
        border: 1px solid $n100;
        border-radius: 8px;
        padding: 32px 24px 16px;

        .field {
            margin-left: 16px;
            margin-top: 12px;
            .small-text {
                color: $n300;
            }
        }
    }

    .score-competitor {
        height: fit-content;
        background: $n0;
        border: 1px solid $n100;
        border-radius: 8px;
        padding: 32px 24px 16px;

        .field {
            margin-left: 16px;
            margin-top: 12px;
        }

        .total-matches-number {
            color: $n500;
        }

        .score-parts {
            column-gap: 32px;
            &-part {
                column-gap: 8px;
                &-legend {
                    width: 16px;
                    height: 16px;
                    border-radius: 30px;
                }

                &-name {
                    color: $n500;
                }
            }
        }
    }

    .club-competitor {
        height: fit-content;
        background: $n0;
        border: 1px solid $n100;
        border-radius: 8px;
        padding: 32px 24px 16px;

        .competitor-profile-club-avatar {
            width: 104px;
            height: 104px;
            max-width: 104px;
            max-height: 104px;
        }
    }
}
