@use '../../../../../../style/variables' as *;

.tournament-draw-tab{
    .rounds-slider{
        background: $n0;
        max-height: fit-content;
    }

    .tournament-match-card-col-wrapper{
        position: relative;
        .match-draw-line{
            position: absolute;
            right: -12px;
            width: 12px;
            z-index: 100;
            border: 2px solid $n100;
            border-left: none;
        }

        .match-draw-back-line{
            position: absolute;
            left: -12px;
            width: 12px;
            height: 2px;
            background-color: $n100;
        }
    }
}