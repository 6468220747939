@use '../../style/variables' as *;

.sidebar-link-wrapper {
    border: 2px solid transparent;
    border-left: 4px solid transparent;
    background: transparent;
    padding: 14px 16px;
    cursor: pointer;
    .icon {
        width: 30px;
        color: $n500;
    }

    svg {
        margin-right: 8px;
    }

    &:hover {
        background-color: $p50;
    }
}

.active-link {
    border: 2px solid transparent;
    border-left: 4px solid $p500;
    background: $p50;
    padding: 14px 16px;
    cursor: pointer;
    .icon {
        width: 30px;
        color: $p500;
    }
    h5 {
        color: $p500;
    }
    svg {
        fill: $p500;
    }
}

.active-link-accordion {
    background-color: $p50;
}

.sidebar-accordion {
    padding: 0;
    width: 100%;
    display: block;

    .accordion-header {
        &:hover {
            background-color: $p50;
        }
    }

    .accordion-item {
        border: none;
    }
    .accordion-button {
        width: 100%;
        background-color: transparent;
        box-shadow: none;
        padding: 0;
        padding-right: 30px;
    }
}

.notification-wrapper {
    position: relative;

    .notification {
        font-family: $font-heading;
        font-weight: 700;
        display: inline-block;
        background: $r500;
        color: $n0;
        border-radius: 50%;
        padding: 3px 8px;
        position: absolute;
        top: 0;
        transform: translate(-5%, -45%);
        font-size: 10px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.03em;
        font-feature-settings: 'liga' off;
    }
}
