@use '../../../../style/variables' as *;

.create-report-wrapper {
    background: $n0 !important;
    height: calc(100% + 100px);

    .report-wrapper {
        height: 100%;
    }

    .report-form-wrapper {
        //background: $n0 !important;
        padding-bottom: 50px;
    }

    .close-icon {
        cursor: pointer;
    }

    h2 {
        color: $text-0;
    }

    // DO not delete this line of code because it is important for buttons to display gap exactly the same on all pages
    .no-min-width {
        .button-wrapper {
            min-width: unset;
        }
    }

    @media only screen and (max-width: $md)  {
        .footer-buttons {
            flex-direction: column;
            .back-button-wrapper {
                min-width: 100%;
            }
            .next-button-wrapper {
                min-width: 100%;
            }
        }
    }

    .footer-buttons {
        gap: 8px;

        .back-button-wrapper{
            max-width: 125px;

            .button-wrapper.back-button{
                min-width: 125px;
            }
        }
        .next-button-wrapper{
            max-width: 292px;
            
            .button-wrapper.next-button{
                min-width: 292px;
            }
        }
    }
}