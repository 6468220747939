@use '../../style/variables' as *;

.clubs-wrapper {
    position: relative;

    .clubs-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h2 {
            margin-bottom: 8px;
        }

        span {
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.025em;
            color: $n300;
        }
    }

    .table {
        background-color: white;
        margin-bottom: 0;

        th {
            padding: 16px 18px;
        }

        td {
            padding: 14px 18px;
            p {
                overflow-wrap: break-word;
                width: 100%;
            }
            a {
                &:hover {
                    color: black;
                }
            }
        }
    }

    .club-list-first-row {
        min-width: 180px;
    }

    .img-wrapper {
        border-radius: 50%;
        width: 24px;
        height: 24px;
        max-width: 24px;
        margin-right: 10px;
        overflow: hidden;

        img {
            width: 100%;
        }
    }

    .text-elipsis {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 110px;
    }
}
