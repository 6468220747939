.file-upload-tag {
    border: none;
    box-shadow: inset 0px -1px 0px #e3e5e8;
    padding: 14px 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .name-part {
        flex: 1 1 100%;
        min-width: 0;

        .file-paperclip{
            min-width: 25px;
        }

        .file-name{
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
        }
    }

    .icons{
        width: 50px;
    }

    &:last-of-type {
        box-shadow: none;
    }
    &:first-of-type {
        box-shadow: inset 0px 1px 0px #e3e5e8, inset 0px -1px 0px #e3e5e8;
    }
}
