@use '../../../style/variables' as *;

.referee-profile-flow-wrapper {
    background: $n50;

    .referee-background {
        background: url('../../../assets/images/cover-profile-competitor.png');
        background-position: center;
        background-size: cover;
    }
    .layout-gap {
        gap: 16px;

        @media only screen and (max-width: $md) {
            gap: 8px;
        }
    }
    /* Change direction of the mobile cards to column except those which have class .small-card-wrapper*/
    .info-card:not(.small-card-wrapper) {
        @media only screen and (max-width: $md) {
            .info-card-content-wrapper {
                flex-direction: column;
                .info-card-content {
                    padding-top: 24px;
                }
            }
        }
    }
.region-card {
    padding: 32px;
    border: 1px solid $n100;
    border-radius: 4px;
}
    .small-icon {
        width: 24px;
        height: 24px;
        fill: $n500;
    }

    .card-profile {
        background: $n0;
        border: 1px solid $n100;
        border-radius: 8px;
    }

    // Commented for now
    // .account-card {
    //     word-break: break-word;
    // }

    .card-title-color {
        color: $n500;
    }

    .small-card-wrapper {
        padding: 24px 24px 32px 24px;
    }

    .competitor-flow-profile-card-wrapper{
        border-radius: 4px;
    }
}
