@use '../../style/variables' as *;

.toast-container {
	margin-top: 20px;
	margin-right: 20px;

	.toast {
		border: none;

		.toast-header.toast-header-wrapper {
			border-radius: 4px;
			padding: 14px 16px;
			border: none;

			&-error {
				background-color: $e500;
			}

			&-success {
				background-color: $g500;
			}

			&-warning {
				background-color: $w500;
			}

			&-info {
				background-color: $b500;
			}

			.close-icon {
				width: 36px;
				height: 36px;

				&-error {
					&:hover {
						background: $e800;
						border-radius: 50%;
						cursor: pointer;
					}

					&:active {
						background: $e400;
						border-radius: 50%;
					}
				}

				&-success {
					&:hover {
						background: $g700;
						border-radius: 50%;
						cursor: pointer;
					}

					&:active {
						background: $g300;
						border-radius: 50%;
					}
				}

				&-warning {
					&:hover {
						background: $w800;
						border-radius: 50%;
						cursor: pointer;
					}

					&:active {
						background: $w400;
						border-radius: 50%;
					}
				}

				&-info {
					&:hover {
						background: $p700;
						border-radius: 50%;
						cursor: pointer;
					}

					&:active {
						background: $p300;
						border-radius: 50%;
					}
				}
			}
		}
	}
}