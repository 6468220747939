.avatar-img {
    width: 32px;
    height: 32px;
    background-color: #fff;
    border-radius: 50%;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 32px;
        border-radius: 50%;
    }
}
