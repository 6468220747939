@use '../../style/variables' as *;

.competitor {
    .nav-tabs {
        padding-left: unset;
    }

    @media only screen and (max-width: $md) {
        .nav::-webkit-scrollbar {
            display: none;
        }

        .nav {
            flex-wrap: nowrap;
            overflow-x: scroll;
        }

        .nav-item {
            // max-height: 54px;

            .nav-link {
                width: max-content;
            }
        }
    }

    .tabs {
        gap: 24px;
    }

    .table {
        padding: 16px 18px;
        background-color: $n0;
        border-radius: 0px 0px 4px 4px;

        th {
            padding: 16px 18px;
        }

        td {
            padding: 14px 18px;

            a {
                &:hover {
                    color: black;
                }
            }
        }
    }

    &-tournaments {
        margin-top: 16px;
        background-color: $n0;

        .competitor-tournaments-club-logo {
            width: 24px;
            height: 24px;
            max-width: 24px;
            max-height: 24px;
            border-radius: 50%;
            box-shadow: none;
        }
    }
}

.step-two {
    display: flex;
    flex-direction: column;
    max-width: 350px;
    margin: 0 auto;
    margin-top: 32px;

    &-wrapper {
        display: flex;
        gap: 100px;
    }

    .form-wrap {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 16px;

        input {
            width: 100%;
            max-width: 56px;
            max-height: 30px;
        }
    }
}
