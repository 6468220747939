@use '../../../../style/variables' as *;

.single-club {
    .card-profile {
        background: $n0;
        border: 1px solid $n100;
        border-radius: 8px;
    }

    @media only screen and (max-width: $md) {
        .nav::-webkit-scrollbar {
            display: none;
        }

        .nav {
            flex-wrap: nowrap;
            overflow-x: scroll;
        }

        .nav-item {
            .nav-link {
                width: max-content;
            }
        }
    }

    .nav-tabs {
        background-color: $n0;
        padding-left: unset;
    }

    .tabs {
        gap: 24px;
    }

    .table {
        background-color: white;

        border-radius: 4px 4px 4px 4px;
        // padding: 0 16px;

        .club:last-child {
            td {
                border-bottom: none;
            }
        }

        th {
            padding: 16px 18px;
        }

        td {
            padding: 14px 18px;

            a {
                &:hover {
                    color: black;
                }
            }
        }
    }

    .club-history {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        top: 0;
        left: 0;
        background: #ffffff;
        width: 100%;
        height: 100%;
        z-index: 200;
        margin-left: -12px;
        margin: 0 auto;
        padding: 60px 0px;

        h2 {
            font-weight: 700;
            font-size: 32px;
            line-height: 39px;
            letter-spacing: -0.02em;
            color: #2c3136;
            margin-left: auto;
        }

        svg {
            margin: -60px 30px 0px 0px;
        }

        table {
            width: 100%;
            max-width: 400px;
        }
    }

    .competition-selection {
        .nav.nav-tabs {
            border-radius: 4px 4px 0 0;
        }

        .table {
            border-radius: 0 0 4px 4px;
        }
    }
}
