@use '../../../../../style/variables' as *;

.court-and-balls-wrapper {
    .awards-section-title {
        color: black;
        margin-bottom: 24px;
    }

    // Removing up and down arrows on input number fields
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }

    // This needs to be here for dropdowns to be displayed as it should be
    // DO NOT DELETE IT!
    .complaint-wrapper {
        > .col {
            padding-left: 0;
        }

        @media only screen and (min-width: $md) {
            > .col {
                padding-right: 0;
            }
        }
    }
}
