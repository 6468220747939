@use "../../../../../style/variables" as *;

.competitor-modal {
    background: $n0 !important;
    min-height: 100vh;

    .close-icon {
        max-width: fit-content;
        cursor: pointer;
    }
}
