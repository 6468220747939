@use '../../style/variables' as *;

.referee-card {
    background: $n0;
    border: 1px solid $n100;
    border-radius: 4px;
    padding: 32px;
    flex: 1;
    min-width: 320px;
    box-sizing: border-box;
    
    @media (max-width: $md) {
        //   flex-basis: 100%; /* Take up full width on mobile */
          min-width: unset; /* Reset minimum width for mobile */
      };

      .close-btn-wrapper {
        width: 32px;
        height: 32px;
        position: absolute;
        top: 16px;
        right: 16px;
      }

      .btn-close {
        opacity: 1;
        background: transparent url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%233B485C%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e') center/1em auto no-repeat;
        box-sizing: content-box;
        position: relative;
        border: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;


        // Mouse hover, not touchscreens
        @media (hover: hover) {
            &:hover {
                background-color: $p50;
                border-radius: 50%;
            }
        }

        // Mouse focus
        &:focus:not(:focus-visible) {
            // background-color: $p200;
            border-radius: 50%;
            box-shadow: none;
        }

        // Keyboard focus
        &:focus-visible {
            background-color: $p100;
            border-radius: 50%;
            box-shadow: 0px 0px 0px 2px #ffffff, 0px 0px 0px 4px #232e40;
            outline: none;
        }

        &:active {
            background-color: $p200;
            border-radius: 50%;
            box-shadow: none;
        }
    }

    .subtitle {
        margin-top: -20px;
    }

    .info-card-content-wrapper {
        flex-wrap:wrap;
        row-gap: 8px;
        
        img {
            width: 70px;
            height: 70px;
            max-width: 70px;
            max-height: 70px;
            border-radius: 8px;
            margin-right: 24px;
        }
    }
}
