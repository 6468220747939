@use '../../../../style/variables' as *;

.create-new-coach-wrapper {
    background: $n0 !important;
    height: calc(100% + 100px);

    .create-coach-wrapper {
        height: 100%;
    }

    .coach-form-wrapper {
        //background: $n0 !important;
        padding-bottom: 50px;
    }

    .close-icon {
        cursor: pointer;
    }

    h2 {
        color: $text-0;
    }

    .form-footer-buttons {
        gap: 8px;

        .footer-button {
            @media only screen and (max-width: $md) {
                width: 100%;
            }
        }
    }
    .form-columns {
        min-width: 296px;
        max-width: 296px;

        @media only screen and (max-width: $md) {
            min-width: 100%;
            max-width: 100%;
        }
    }
}
