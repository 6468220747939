@use '../../../../style/variables' as *;

.club-ranking-wrapper {
    .tabular-navigation {
        border-radius: 4px 4px 0px 0px;
        .nav-tabs{
            background: $n0;
        }
    }

}
