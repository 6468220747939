@use '../../style/variables' as *;

.table-referee-admin-competitions-pagination-wrapper {
    p {
        color: $text-0;
    }

    .link {
        color: $p500;
    }

    th {
        padding: 18px 16px;

        h5 {
            color: $text-0;
        }
    }
    td {
        padding: 16px;
    }

    .table-referee-admin-competitions-avatar-club {
        max-width: 24px;
        max-height: 24px;
        border-radius: 50%;
    }
}
