@use '../../style/variables' as *;

.radio-wrapper {
    .form-check-input {
        background: $n0;

        width: 17px;
        height: 17px;

        border: 2px solid $n200;
        border-radius: 4px;
        box-shadow: none;
        cursor: pointer;
    }

    .form-check-input:checked {
        border: none;
        background-position: center;
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' rx='4' fill='%23466C8F'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.2871 4.94192C16.7489 5.34601 16.7957 6.04797 16.3917 6.50979L8.61389 15.3987C8.41138 15.6301 8.12202 15.7673 7.81467 15.7775C7.50732 15.7877 7.20947 15.6701 6.99202 15.4527L3.65869 12.1193C3.22477 11.6854 3.22477 10.9819 3.65869 10.548C4.0926 10.1141 4.79612 10.1141 5.23004 10.548L7.72357 13.0415L14.7193 5.04645C15.1234 4.58463 15.8253 4.53783 16.2871 4.94192Z' fill='white'/%3E%3C/svg%3E%0A");
    }
}

label {
    font-family: $font-heading;
}
