@use '../../../style/variables' as *;

.tournaments-wrapper {
    position: relative;
    height: 100%;

    .tournaments-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h2 {
            margin-bottom: 8px;
        }

        span {
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.025em;
            color: $n300;
        }
    }

    .table {
        background-color: white;
        margin-bottom: 0;

        th {
            padding: 16px 18px;
        }

        td {
            padding: 14px 18px;

            a {
                &:hover {
                    color: black;
                }
            }
        }
    }

    // .img-wrapper {
    //     border-radius: 50px;
    //     width: 100%;
    //     max-width: 24px;
    //     margin-right: 10px;
    //     overflow: hidden;

    //     img {
    //         width: 100%;
    //     }
    // }

    .competitor-tournaments-club-logo {
        width: 24px;
        height: 24px;
        max-width: 24px;
        max-height: 24px;
        border-radius: 50%;
        box-shadow: none;
    }

    // .club-card.competitor-tournaments-card {
    //     img {
    //         width: 24px;
    //         height: 24px;
    //         max-width: 24px;
    //         max-height: 24px;
    //         border-radius: 50%;
    //     }
    // }

    .text-elipsis {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 110px;
    }
}

.tournament-toast {
    background: #3d6c9e;
    max-width: 500px;
    width: 100%;
    padding: 10px;

    p {
        color: white;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
    }

    svg {
        margin-right: 8px;
    }

    button {
        margin-left: auto !important;
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(242deg)
            brightness(111%) contrast(101%);
        opacity: 1;
        transform: scale(1.2);
    }
}

.form {
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    max-width: 300px;
    margin: 0 auto;
}
