@use '../../style/variables' as *;

.select-wrapper {
    width: 100% !important;
    .form-select {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'%3E%3Ctitle%3ECaret Down%3C/title%3E%3Cpath d='M98 190.06l139.78 163.12a24 24 0 0036.44 0L414 190.06c13.34-15.57 2.28-39.62-18.22-39.62h-279.6c-20.5 0-31.56 24.05-18.18 39.62z'/%3E%3C/svg%3E");
        width: 100%;
        background-size: 20px 20px;
    }

    .sel {
        border: 1px solid $n200;
        border-radius: 4px;
        outline: none;
        padding: 14px 16px;
        box-shadow: none;
    }
}
