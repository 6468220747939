@use '../../style/variables' as *;

.table-referee-card-wrapper {
    margin: 5px 1px 1px !important;
    .link {
        padding: 0;
        margin: 0;
        text-decoration-line: underline;
        cursor: pointer;
        color: #3d6c9e;
    }

    .table-referee-card-ref-avatar {
        width: 24px;
        height: 24px;
        max-width: 24px;
        max-height: 24px;
        box-shadow: 0 0 0 1px $p300; // It must be box shadow, because if we use outline it wont work on IOS devices
        border-radius: 50%;
    }

    .table-card-divider {
        margin-top: 18px;
        margin-bottom: 18px;
    }
}
