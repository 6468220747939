@use '../../style/variables' as *;

.tournament-match-card {
    border: 1px solid $n100;
    border-radius: 10px;
    background-color: $n0;

    .result-wrapper {
        border-bottom: 1px solid $n100;

        .sets-result-number {
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: $n100;
            border-right: 1px solid $n100;
            p {
                color: $n500;
            }
        }

        .green-indicator {
            background-color: $g50;
        }

        .red-indicator {
            background-color: $s50;
        }

        .games-result-number {
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: $n50;
            border-right: 1px solid $n100;

            p {
                color: $n500;
            }
        }

        .games-result-number-text-field {
            width: 32px;
            height: 32px;
            margin-left: 2px;

            input {
                width: 32px;
                height: 32px;
                padding: 0;
                border: 2px solid $p500;
                text-align: center;

                &:focus {
                    border: 2px solid $p500;
                }
            }
        }
    }

    .tournament-match-card-buttons {
        &.button-wrapper {
            padding-left: 20px;
            padding-right: 20px;
        }

        &.middle-button {
            &.button-wrapper {
                border-radius: 0;
            }
        }

        &:first-child {
            &.button-wrapper {
                border-radius: 8px;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        &:last-child {
            &.button-wrapper {
                border-radius: 8px;
                border-top-right-radius: 0;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        &.single-button {
            &.button-wrapper {
                border-radius: 8px;
                border-top-right-radius: 0;
                border-top-left-radius: 0;
            }
        }
    }
}

.tournament-match-card-row {
    border-bottom: 1px solid $n100;

    img {
        width: 30px;
        height: 30px;
    }
}
