@use '../../../../style/variables' as *;

.clubs-ranking-settings-wrapper {
    .tab-title {
        border-bottom: 1px solid $n100;
        background-color: $n0;
    }

    .tabular-navigation {
        background: $n0;
        border-bottom: 1px solid $n100;
        border-radius: 4px 4px 0px 0px;
    }

    &.clubs-ranking-settings {
        .tab-pane {
            background-color: $n50;
        }
    }

    @media only screen and (max-width: $md) {
        .nav::-webkit-scrollbar {
            display: none;
        }

        .nav {
            flex-wrap: nowrap;
            overflow-x: scroll;
        }
    }
}
