@use '../../style/variables' as *;

.required {
    color: $e500;
}

.input-wrapper {

    input {
        width: 100%;
        background: $n0;
        border: 1px solid $n200;
        border-radius: 4px;
        outline: none;
        padding: 10px 8px;

        &:disabled {
            background-color: hsl(0, 0%, 95%);
            border-color: $n200;
            color: hsl(0, 0%, 60%);
            // background: transparent;
        }
    }

    input::placeholder {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.025em;
        color: $n200;
    }

    input[type='text']:focus,
    input[type='password']:focus,
    input[type='email']:focus {
        outline: none;
        border: 1px solid $p900;
    }

    input[type='date']::-webkit-calendar-picker-indicator {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'%3E%3Ctitle%3ECalendar%3C/title%3E%3Crect fill='none' stroke='currentColor' stroke-linejoin='round' stroke-width='32' x='48' y='80' width='416' height='384' rx='48'/%3E%3Ccircle cx='296' cy='232' r='24'/%3E%3Ccircle cx='376' cy='232' r='24'/%3E%3Ccircle cx='296' cy='312' r='24'/%3E%3Ccircle cx='376' cy='312' r='24'/%3E%3Ccircle cx='136' cy='312' r='24'/%3E%3Ccircle cx='216' cy='312' r='24'/%3E%3Ccircle cx='136' cy='392' r='24'/%3E%3Ccircle cx='216' cy='392' r='24'/%3E%3Ccircle cx='296' cy='392' r='24'/%3E%3Cpath fill='none' stroke='currentColor' stroke-linejoin='round' stroke-width='32' stroke-linecap='round' d='M128 48v32M384 48v32'/%3E%3Cpath fill='none' stroke='currentColor' stroke-linejoin='round' stroke-width='32' d='M464 160H48'/%3E%3C/svg%3E");
        scale: 1.5;
    }
}
