@use '../../style/variables' as *;

.create-new-club-wrapper {
    background: $n0 !important;
    height: calc(100% + 100px);

    .create-club-wrapper {
        height: 100%;
    }

    .form-wrapper {
        padding-bottom: 50px;
    }

    .close-icon {
        cursor: pointer;
    }

    h2 {
        color: $text-0;
    }

    .club-form-wrapper {
        .form-footer-buttons {
            gap: 8px;

            .footer-button {
                @media only screen and (max-width: $md) {
                    width: 100%;
                }
            }
        }
        .form-columns {
            min-width: 296px;
            max-width: 296px;

            @media only screen and (max-width: $md) {
                min-width: 100%;
                max-width: 100%;
            }

            .step-five-wrapper {
                .all-fields-wrapper {
                    gap: 12px;

                    .all-fields-text {
                        color: $n500;
                    }

                    .all-fields-input {
                        min-width: 56px;
                        max-width: 56px;
                        input {
                            padding: 2px 8px;
                        }
                    }
                }

                .single-field-wrapper {
                    gap: 8px;

                    .single-field-input {
                        min-width: 56px;
                        max-width: 56px;
                        input {
                            padding: 2px 8px;
                        }
                    }
                    .single-field-checkbox {
                        .checkbox-label {
                            color: $n500;
                        }
                    }
                }
            }

            .step-six-wrapper {
                .club-description-textarea {
                    margin-bottom: 4px !important;
                }
                .club-history-max-length {
                    color: $n400;
                    letter-spacing: 0.02em;
                }
            }
        }
    }
}
