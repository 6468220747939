@use '../../../../../style/variables' as *;

.tournaments-tab-wrapper {
    .tabular-navigation {
        background: $n0;
        border-bottom: 1px solid $n100;
        border-radius: 4px 4px 0px 0px;
    }

}
