@use '../../../style/variables' as *;

.no-data-img {
    width: 271px;
}

.coaches-wrapper {
    .coaches-buttons {
        column-gap: 8px;
    }
}
