    .clubs-participants-table {
        background-color: white;
        margin-bottom: 0;

        th {
            padding: 16px 18px;
        }

        td {
            padding: 14px 18px;

            a {
                &:hover {
                    color: black;
                }
            }
        }
    }