@use '../../style/variables' as *;

.table-card-wrapper {
    margin: 5px 1px 1px !important;

    .competitor-table-card-avatar {
        max-width: 24px;
        max-height: 24px;
        box-shadow: 0 0 0 1px $p300; // It must be box shadow, because if we use outline it wont work on IOS devices
        border-radius: 50%;
    }
    .competitor-table-card-avatar-club {
        max-width: 24px;
        max-height: 24px;
        border-radius: 50%;
    }

    .table-card-divider {
        margin-top: 18px;
        margin-bottom: 18px;
    }
}
