@use "../../../../../style/variables" as *;

.remove-player-from-club-wrapper {
    background: $n0 !important;
    overflow-x: none;

    .form-wrapper {
        background: $n0 !important;
        padding-bottom: 30px;
    }

    .close-icon {
        cursor: pointer;
    }

    h2 {
        color: $text-0;
    }
}
