@use '../../../style/variables' as *;

.referee-competitions-ranking-wrapper {
    .tabular-navigation {
        background: $n0;
        border-bottom: 1px solid $n100;
        border-radius: 4px 4px 0px 0px;
    }

    .referee-competitions-card {
        border: none;
        border-radius: 0;
    
        &.white-referee-competition-card {
            background-color: $n0;
            .ref-comp-card-row {
                padding: 16px 0;
                border-bottom: 1px solid $n100;
    
                &:last-child{
                    border-bottom: none;
                }
            }
        }
    
        &.grey-referee-competition-card {
            background-color: $n100;
            .ref-comp-card-row {
                padding: 16px 0;
                border-bottom: 1px solid $n0;
    
                &:last-child{
                    border-bottom: none;
                }
            }
        }
    }
}
