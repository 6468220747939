@use '../../style/variables' as *;

.table-couches-pagination-wrapper {
    background-color: $n0;
    border-radius: 0px 0px 4px 4px;

    hr {
        margin: 0;
    }

    .link {
        color: $p500;
    }

    th {
        padding: 18px 16px;

        h5 {
            color: $n700;
        }
    }
    td {
        border-bottom: 1px solid #e3e5e8;
        // box-shadow: inset 0px -1px 0px #e3e5e8;
        padding: 16px;
    }

    .table-coaches-pagination-coach-avatar {
        width: 24px;
        height: 24px;
        max-width: 24px;
        max-height: 24px;
        box-shadow: 0 0 0 1px $p300; // It must be box shadow, because if we use outline it wont work on IOS devices
        border-radius: 50%;
    }

    .link-align-end {
        text-align: end;
    }

}
