@use '../../../../style/variables' as *;

.coach-profile-wrapper {
    .card-profile {
        background: $n0;
        border: 1px solid $n100;
        border-radius: 8px;
    }
    .couch-background {
        background: url('../../../../assets/images/competition-img-1.jpg');
        background-position: center;
        background-size: cover;
    }

    .card-title-color {
        color: $n500;
    }

    .competitor-flow-profile-card-wrapper{

        border-radius: 4px;
    }
}
