@use '../../../style/variables' as *;

.user-profile {
    gap: 24px;

    @media only screen and (max-width: $md) {
        gap: 8px;
    }

    .card-title-color {
        color: $n500;
    }
}

.competitor-flow-profile-card-wrapper {
    border: 1px solid $n100;
    border-radius: 8px;

    .competitor-flow-profile-card-body {
        padding: 32px 24px 16px 24px;

        &.details-card {
            .details-header-wrapper {
                h5.details-h5 {
                    color: $p600;
                    cursor: pointer;
                }
            }

            .details-single-info {
                padding: 6px 16px;

                .title {
                    color: $n300;
                }

                .data-info {
                    color: $n500;
                }
            }
        }
    }

    .competitor-flow-profile-card-footer {
        padding: 16px 24px;
        background-color: $n0;
        border-top: 1px solid $level-2;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
}

.competition {
    gap: 24px;
    max-height: 600px;

    .total-matches {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;

        p {
            margin-bottom: 14px;
        }
    }

    .matches {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 32px;
        margin-bottom: 60px;
        margin-top: 24px;

        .match {
            gap: 8px;
        }

        .green {
            width: 16px;
            height: 16px;
            border-radius: 30px;
            background-color: $g500;
        }

        .red {
            width: 16px;
            height: 16px;
            border-radius: 30px;
            background-color: $e500;
        }
    }
}

.club {
    .club-history {
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 22px;
        gap: 10px;

        svg {
            width: 100%;
            max-width: 32px;
            background: $n50;
            padding: 4px;
            border-radius: 40px;
        }

        .small-text-gray {
            color: $n400;
        }
    }

    img.club-image {
        width: 56px;
        height: 56px;
        max-width: 56px;
        max-height: 56px;
        border: 1px solid $n200;
        border-radius: 100px;
        object-fit: contain;
    }
}
