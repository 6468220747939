@use '../../style/variables' as *;

.card-profile-cover {
    background: url('../../assets/images/cover-profile-competitor.png');
    background-position: center;
    background-size: cover;
    border-radius: 8px 8px 0 0;
    height: 214px;
    position: relative;

    .card-info {
        margin-top: 138px;
        @media only screen and (max-width: $xl) {
            margin-top: 106px;
        }

        h1,
        p:not(.pill-content) {
            color: $n0;
        }

        h1 {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;

            @media only screen and (min-width: $md) {
                max-width: 70%;
            }

            @media only screen and (min-width: $lg) {
                max-width: 95%;
            }
        }

        p.pill-content {
            color: $n500;
        }

        .profile-card-avatar-place {
            width: 170px;
        }

        .profile-card-info {
            margin-left: 32px;
            row-gap: 8px;
        }
    }

    .profile-picture-avatar {
        position: absolute;
        width: 156px;
        height: 156px;
        left: 25px;
        bottom: -38px;
        box-shadow: 0 0 0 1px $p300; // It must be box shadow, because if we use outline it wont work on IOS devices
        border-radius: 50%;
        background-color: $n0;
    }

    .pill-style {
        padding-top: 2px;
        padding-bottom: 2px;
    }
}

.card-profile-cover-mobile {
    background: url('../../assets/images/cover-profile-competitor.png');
    background-position: center;
    background-size: cover;
    border-radius: 8px 8px 0 0;
    height: 112px;

    &.pill-card {
        height: 128px;
    }

    .card-info-mobile {
        h5,
        p:not(.pill-content) {
            padding: 0;
            color: $n0;
        }

        p.pill-content {
            color: $n500;
        }

        .profile-picture-mobile {
            width: 64px;
            height: 64px;
            box-shadow: 0 0 0 1px $p300; // It must be box shadow, because if we use outline it wont work on IOS devices
            border-radius: 50%;
            background-color: $n0;
        }

        .profile-card-mobile-avatar-place {
            width: 64px;
        }

        .profile-card-mobile-info {
            margin-left: 14px;
            height: 100%;
        }
    }

    .pill-style {
        padding-top: 2px;
        padding-bottom: 2px;
    }
}
