//Fonts
@font-face {
    font-family: 'Source Sans Pro Bold';
    src: url('../assets/fonts/Source_Sans_Pro/SourceSansPro-Bold.ttf');
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../assets/fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf');
    font-style: normal;
    font-weight: 400;
}

$font-main: 'Source Sans Pro Bold';
$font-heading: 'Source Sans Pro';

//Colors

//Primary

$p500: #25527b;
$p50: #e5eaef;
$p100: #becbd7;
$p200: #92a9bd;
$p300: #6686a3;
$p400: #466c8f;
$p600: #214b73;
$p700: #1b4168;
$p800: #16385e;
$p900: #0d284b;

//Secondary

$s500: #c96c59;
$s50: #f9edeb;
$s100: #efd3cd;
$s200: #e4b6ac;
$s300: #d9988b;
$s400: #d18272;
$s600: #c36451;
$s700: #bc5948;
$s800: #b54f3e;
$s900: #a93d2e;

//Neutral

$n500: #2c3136;
$n0: #ffffff;
$n50: #f5f5f5;
$n100: #e0e0e1;
$n200: #96989b;
$n300: #6b6f72;
$n400: #4c5054;
$n600: #272c30;
$n700: #212529;
$n800: #1b1f22;
$n900: #101316;

//Success

$g500: #4b852d;
$g50: #e9f0e6;
$g100: #c9dac0;
$g200: #a5c296;
$g300: #81aa6c;
$g400: #66974d;
$g600: #447d28;
$g700: #3b7222;
$g800: #33681c;
$g900: #235511;

//Warning

$w500: #eb7f00;
$w50: #fdf0e0;
$w100: #f9d9b3;
$w200: #f5bf80;
$w300: #f1a54d;
$w400: #ee9226;
$w600: #e97700;
$w700: #e56c00;
$w800: #e26200;
$w900: #dd4f00;

//Error

$e500: #d23e3f;
$e50: #fae8e8;
$e100: #f2c5c5;
$e200: #e99f9f;
$e300: #e07879;
$e400: #d95b5c;
$e600: #cd3839;
$e700: #c73031;
$e800: #c12829;
$e900: #b61b1b;
$r500: #d61f21;

// text color
$text-0: #3b485c;
$level-2: #e3e5e8;

// Success green
$sg50: #ecfdf3;
$sg50-2: #e9f0e6;
$sg700: #027a48;
$sg500: #12b76a;

//Toast info base color
$b500: #3D6C9E;
// Global settings

body {
    background-color: $n0;
}

//Typography

h1 {
    font-family: $font-main;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    color: $n500;
    padding: 0;
    margin: 0;
}

h2 {
    font-family: $font-main;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: $n500;
    padding: 0;
    margin: 0;
}

h3 {
    font-family: $font-main;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: $n500;
    padding: 0;
    margin: 0;
}

h4 {
    font-family: $font-main;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: $n500;
    padding: 0;
    margin: 0;
}

h5 {
    font-family: $font-main;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: $n500;
    padding: 0;
    margin: 0;
}

h6 {
    font-family: $font-main;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: $n500;
    padding: 0;
    margin: 0;
}

p {
    font-family: $font-heading;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.025em;
    color: $n800;
    padding: 0;
    margin: 0;
}

.caption-text {
    font-family: $font-main;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: $n300;
    padding: 0;
    margin: 0;
}

.small-text {
    font-family: $font-heading;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $n800;
    padding: 0;
    margin: 0;
}

.link {
    font-family: $font-heading;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.025em;
    text-decoration-line: underline;
    color: $p500;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.red-link {
    font-family: $font-heading;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.025em;
    text-decoration-line: underline;
    color: $e500 !important;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.muted-text {
    color: $n400;
}

.required-asterisk{
    color: $e500;
    font-family: $font-main;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    padding: 0;
    margin: 0;
    vertical-align: top;
}

.background-table-card:nth-child(even) {
    background-color: $n100;
}
.background-table-card:nth-child(odd) {
    background-color: $n0;
}

.table-card-divider {
    background-color: $n100;
    height: 1px;
    margin-top: 10px;
}

.card-left-button {
    border-radius: 9px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.card-right-button {
    border-radius: 9px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

// Breakpoints
$xs: 0;
$sm: 576px;
$md: 768px;
$xl: 1200px;
$lg: 992px;

// Breadcrumb global style
.breadcrumb-item {
    font-family: $font-heading;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.025em;
    color: $n500;

    a {
        font-family: $font-heading;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.025em;
        color: $n500;
        padding: 0;
        margin: 0;
    }

    &.active {
        font-family: $font-heading;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.025em;
        color: $n300;
    }

    @media only screen and (max-width: $md) {
        & {
            font-family: $font-heading;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: $n500;
            letter-spacing: unset;
        }

        a {
            font-family: $font-heading;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: $n500;
            letter-spacing: unset;
        }

        &.active {
            font-family: $font-heading;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: $n300;
            letter-spacing: unset;
        }
    }
}

// Global settings for pagination
.pagination.mobile-pagination {
        margin: 0;

        .page-item{

            &.mobile-prev-next {
                .page-link {
                    border: 1px solid $n200;
                    padding-left: 1rem;
                    padding-right: 1rem;
                    > span {
                        font-size: 1rem;
                    }
                }
            }
        }
}

.pagination:not(:has(.mobile-pagination)) .page-item {
    .page-link {
        border: none;
    }
}

.pagination-wrapper {
    padding: 5px 10px;
    background-color: $n0;

    svg {
        transform: scale(0.7);
    }

    ul {
        margin: 0;

        li {
            padding: unset;
            a {
                border: none;

                &:focus,
                &:active {
                    box-shadow: none;
                }
            }
        }
    }

    p {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: $n700;
    }
}

.table {
    img {
        border-radius: 50%;
    }
}

// Bootstrap breakpoints overload
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 2000px,
);
