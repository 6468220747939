@use '../../style/variables' as *;

.text-area-wrapper {
    .form-control {
        background: $n0;
        border: 1px solid $n200;
        border-radius: 4px;
        box-shadow: none;
    }

    .required {
        color: $e500;
    }
}
