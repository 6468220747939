@use '../../style/variables' as *;

.competitions-participation-table {
    .competitor-img {
        width: 30px;
        height: 30px;
        max-height: 30px;
        max-width: 30px;
        box-shadow: 0 0 0 1px $p300; // It must be box shadow, because if we use outline it wont work on IOS devices
    }

    .first-column {
        p {
            color: $n700;
        }
    }

    .p-columns {
        color: $text-0;
    }

    .draw-table-participant-name {
        color: $n500;
    }
    .draw-table-participant-club {
        color: $n300;
    }
}
