@use '../../style/variables' as *;

.search-input-wrapper {
    width: 100%;
    background: $n0;
    border: 1px solid $n200;
    border-radius: 4px;
    outline: none;
    padding: 11px 16px;

    .icon {
        width: 20px;
    }

    .input-style {
        border: none;
        padding: 0;
        box-shadow: none;
    }

    input::placeholder {
        font-family: $font-heading;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.05em;
        color: $n300;
    }
}
