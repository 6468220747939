@use '../../style/variables' as *;

.group-participant-card {
    .group-statistic-col {
        min-width: fit-content;
        text-align: center;
        border-left: 1px solid $n100;
    }

    .group-position-col {
        min-width: fit-content;
        text-align: center;
        background-color: $n50;
        border-radius: 10px;
    }

    .round-robin-card {
        border-bottom: none;
    }
}
