@use '../../style/variables' as *;

.tournaments-table-wrapper {
    background-color: $n0;

    th {
        padding: 18px 16px;

        h5 {
            color: $p700;
        }
    }

    td {
        padding: 16px;
    }
}
