@use "../../style/variables" as *;

.login-wrapper {
    padding: 0 8vw;

    .header {
        margin-top: 56px;
    }

    .forgot-pass{
        color: $n400;
    }
    .chainapp-text {
        font-size: 12px;
    }
}
