@use '../../../style/variables' as *;

.medical-history-card-wrapper{
	margin: 5px 1px 1px !important;

    .table-card-divider {
        margin-top: 16px;
        margin-bottom: 16px;
    }

    &.white-history-card {
        background-color: $n0;
    }

    &.grey-history-card {
        background-color: $n100;
    }

	.medical-header{
		color: $text-0;
	}

	.medical-info-text {
		color: $n500;
	}
}