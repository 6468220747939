@use '../../style/variables' as *;

.admin-wrapper{
    .table-header{
        background: $n0;
        padding: 16px 8px;
        border-bottom: 1px solid $n100;
    }

    .table-footer{
        display: flex;
        justify-content: flex-end;
        background: $n0;
        padding: 8px;
        border-bottom: 1px solid $n100;
    }
}