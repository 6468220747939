@use '../../style/variables' as *;

.referee-admin-competitions-card {
    border: none;
    border-radius: 0;

    &.white-referee-admin-competition-card {
        background-color: $n0;
        .ref-comp-card-row {
            padding: 16px 0;
            border-bottom: 1px solid $n100;
        }
    }

    &.grey-referee-admin-competition-card {
        background-color: $n100;
        .ref-comp-card-row {
            padding: 16px 0;
            border-bottom: 1px solid $n0;
        }
    }

    .table-referee-admin-competitions-card-avatar-club {
        max-width: 24px;
        max-height: 24px;
        border-radius: 50%;
    }
}
