@use '../../../../style/variables' as *;

.swap-up-button{
    padding: 4px;
    border-radius: 2px;
    svg{
        height: 12px;
        transform: rotate(-90deg);

        path{
            fill: $p100;
        }
    }
    cursor: pointer;
    transition: 100ms;
    &:hover{
        background-color: $p50;
    }
}


.swap-down-button {
    padding: 4px;
        border-radius: 2px;
    svg {
        height: 12px;
        transform: rotate(90deg);
        path {
            fill: $p200;
        }
    }
    cursor: pointer;
    transition: 100ms;
    &:hover {
        background-color: $p50;
    }
}