@use '../../style/variables' as *;

.navbar {
    .navbar-toggler {
        border: none;

        .navbar-toggler-icon {
            color: #000;
        }
    }

    svg {
        width: 24px !important;
        height: 24px !important;
    }

        .profile-image-dropdown {
            button {
                background-color: transparent;
                border: none;
                color: $p700;
                display: flex;
                align-items: center;
    
                &:hover {
                    background-color: transparent;
                    color: $p700;
                }
    
                &:active {
                    background-color: transparent;
                    color: $p700;
                }
    
                &:focus {
                    background-color: transparent;
                    color: $p700;
                }
            }
    
            .dropdown-toggle {
                background-color: transparent;
                color: $p700 !important;
                box-shadow: none !important;
                border: none !important;
    
                &::after {
                    display: none;
                }
            }
    
            .dropdown-item {
                padding: 12px;
    
                svg {
                    width: 24px;
                    height: 24px;
                }
    
                p {
                    color: $n500;
                }
            }
    
            .dropdown-menu {
                margin-top: 10px;
                padding-top: 0;
                padding-bottom: 0;
                border-radius: 4px;
    
                a {
                    &:hover {
                        border-radius: 4px;
                        background-color: $p50;
                    }
    
                    &:active {
                        background-color: $n100;
                        border-radius: 4px;
                    }
                }
            }
        }
    .language-selector {
        button {
            background-color: transparent;
            border: none;
            color: $p700;
            display: flex;
            align-items: center;

            &:hover {
                background-color: transparent;
                color: $p700;
            }

            &:active {
                background-color: transparent;
                color: $p700;
            }

            &:focus {
                background-color: transparent;
                color: $p700;
            }
        }

        .dropdown-toggle {
            background-color: transparent;
            color: $p700 !important;
            box-shadow: none !important;
            border: none !important;
        }

        .dropdown-menu {
            @media only screen and (max-width: $md) {
                margin-top: 10px;
            }
        }
    }
}

.offcanvas {
    @media only screen and (max-width: $md) {
        max-width: 80%;
    }

    .offcanvas-body {
        svg {
            width: 24px;
            height: 24px;
        }
    }
}

.header-profile-image {
    width: 40px;
    height: 40px;
    max-width: 40px;
    max-height: 40px;
    border-radius: 50%;
    box-shadow: 0 0 0 1px $p300; // It must be box shadow, because if we use outline it wont work on IOS devices
}