@use '../../style/variables' as *;

.dropdown-wrapper.rows-per-page-dropdown-wrapper {
	.dropdown-toggle {
		padding: 6px 12px;
		min-width: 68px;
	}

	.dropdown-menu {
		.dropdown-item {
			padding: 6px 12px;
		}
	}
}