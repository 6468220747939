@use '../../../style/variables' as *;

.referee-admin-single-competition {
    .tabular-navigation {
        border-bottom: 1px solid $n100;
        border-radius: 4px 4px 0px 0px;
        .nav-tabs {
            background: $n0;
        }
    }
    @media only screen and (max-width: $md) {
        .nav::-webkit-scrollbar {
            display: none;
        }

        .nav {
            flex-wrap: nowrap;
            overflow-x: scroll;
        }

        .nav-item {
            .nav-link {
                width: max-content;
            }
        }
    }
    @media only screen and (max-width: $md) {
        .nav-tabs .nav-link {
            padding: 16px 16px 16px 10px !important;
        }
    }

    .rounds-wrapper {
        flex-wrap: nowrap;
        overflow-x: scroll;

        &::-webkit-scrollbar {
            width: 18px;
            @media only screen and (max-width: $md) {
                width: 0;
            }
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: rgba(26, 26, 26, 0.1);
            border-radius: 100px;
            border: 7px solid $n50;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: rgba(26, 26, 26, 0.2);
        }
    }

    .competition-info-card {
        background: $n0;
        border: 1px solid $n100;
        border-radius: 4px;
        padding: 32px;

        .small-text {
            color: $n300;
        }
    }

    .competiton-region-card {
        border: 1px solid $n100;
        padding: 16px;
    }

    .tabular-navigation {
        border-bottom: none;
    }

    .tournament-description-images img {
        width: 100%;
    }

    .info-card {
        @media only screen and (max-width: $md) {
            .info-card-content-wrapper {
                flex-direction: column;
                .info-card-content {
                    padding-top: 24px;
                }
            }
        }
        .card-p-color {
            color: $n500;
        }

        &.main-referee-wrapper,
        &.ball-wrapper {
            h3,
            p {
                width: max-content;
            }
        }
    }

    .small-card-wrapper {
        padding: 24px;
    }

    .info-wrapper {
        gap: 16px;
        @media only screen and (max-width: $md) {
            gap: 8px;
        }
    }

    .layout-gap {
        gap: 16px;
        @media only screen and (max-width: $md) {
            gap: 8px;
        }
    }
}
