@use '../../style/variables' as *;

.multi-select-search-async {
    .multi-value {
        background-color: $n0;
        border: 1px solid $n300;
        border-radius: 24px;
        width: max-content;
    }
}

.competitor-select-option {
    .select-option-with-image-wrapper {
        gap: 8px;
    }

    .select-option-img {
        width: 40px;
        height: 40px;
        max-width: 40px;
        max-height: 40px;
        box-shadow: 0 0 0 1px $p300; // It must be box shadow, because if we use outline it wont work on IOS devices
        border-radius: 50%;
    }
}