@use '../../style/variables' as *;

.user-card-wrapper {
    background: $p50;
    border-radius: 8px;

    .email {
        color: $n400;
    }

    .profile-info-image {
        width: 40px;
        height: 40px;
        max-width: 40px;
        max-height: 40px;
        border-radius: 50%;
        box-shadow: 0 0 0 1px $p300; // It must be box shadow, because if we use outline it wont work on IOS devices
    }
}
